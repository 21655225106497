.wrapper {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
  font-size: 1.3rem;
  white-space: pre;
}

.title {
  margin-right: auto;
  line-height: 1;
  font-size: 3.2rem;
}

.addButton {
}

.buttonWrapper {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.entryButton {
  margin-left: 2rem;
}

.entryName {
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;

  &:hover {
    background: rgba(0, 0, 0, 0.075);
  }

  &:nth-child(odd) {
    background: rgba(0, 0, 0, 0.05);

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.wrapper {
}
