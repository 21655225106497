// Colors
$colorWhite: #ffffff;
$colorLightGrey: #888888;
$colorBlack: #292929;
$colorRed: #dc2127;
$colorGreen: #55ca50;

// Tiles
$colorTileBorder: #f6f6f6;
$colorTileBackground: rgba(32, 33, 36, 0.015);
$colorTileHoverBorder: darken($colorTileBorder, 5%);
$colorTileHoverBackground: rgba(32, 33, 36, 0.03);

// Copy
$colorCopy: $colorBlack;
$colorCopyMuted: $colorLightGrey;

// Input
$colorInput: $colorCopy;
$colorInputBorder: darken($colorTileBorder, 10%);
$colorInputBorderActive: $colorRed;
$colorInputBackground: $colorTileBackground;

// Buttons
$buttonColor: $colorRed;

$hollowButtonBorderColor: $buttonColor;
$hollowButtonColor: $buttonColor;
$hollowButtonBackground: $colorWhite;
$hollowButtonHoverBorderColor: darken($hollowButtonBorderColor, 7.5%);
$hollowButtonHoverColor: $hollowButtonColor;

$solidButtonBackground: $buttonColor;
$solidButtonColor: $colorWhite;
$solidButtonHoverBackground: darken($solidButtonBackground, 7.5%);
$solidButtonHoverColor: $solidButtonColor;
