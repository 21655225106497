@import "../../data/colors";

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 2px solid $colorTileBorder;
}

.fallbackMessage {
  font-size: 1.4rem;
  text-align: center;
  padding: 4rem 0;
  border-top: 2px solid $colorTileBorder;
  border-bottom: 2px solid $colorTileBorder;
}

.listInformation {
  display: flex;
  padding: 2rem 3rem;
  font-size: 1.4rem;
}

.durationSum {
  margin-left: auto;

  span {
    margin-right: 2rem;
  }

  strong {
    color: $colorRed;
  }
}
