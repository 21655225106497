@import "../../data/colors";

.EventItem {
  display: flex;
  padding: 2rem 3rem;
  font-size: 1.4rem;
  border-top: 2px solid $colorTileBorder;
  color: $colorCopy;
  transition: all 0.2s ease-out;

  @media print {
    padding: 1rem 3rem;
  }

  &:hover {
    background: $colorTileHoverBackground;
  }
}

.Badge {
  display: inline-block;
  width: 2ex;
  padding-bottom: 2ex;
  border-radius: 0.75ex;
  background-color: #ccc;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;

  .ignored & {
    opacity: 0.25;
  }
}

.column:not(:first-child) {
  margin-left: 2rem;
}

.eventDetails {
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-out;

  .ignored & {
    opacity: 0.25;
  }

  @media print {
    margin-left: 0 !important;
  }
}

.badge {
  font-size: 1.5rem;

  @media print {
    display: none;
  }
}

.summary {
  position: relative;
  font-size: 1em;
  font-weight: bold;
  color: currentColor;
  text-decoration: none;
  display: flex;

  span {
    display: inline-block;
    position: relative;
  }

  &:hover {
    text-decoration: underline;
  }
}

.date {
  color: $colorCopyMuted;
  margin-top: 0.25rem;
}

.timeInformation {
  display: flex;
  margin-left: auto;
  color: $colorCopyMuted;

  .duration {
    font-weight: bold;
    margin-left: 2rem;
    color: $colorCopy;
  }
}

.toggleButton {
  width: 2.5rem;
  margin-left: 2rem;
  outline: none;

  @media print {
    display: none;
  }
}

.toggleButtonIcon {
  transition: all 0.2s ease-in-out;
  fill: $colorRed;

  .ignored & {
    fill: $colorGreen;
  }
}
