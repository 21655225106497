@import "../../data/colors";

.Header {
  background: $colorTileHoverBackground;

  @media print {
    display: none;
  }
}

.wrapper {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: -1rem;
}

.button {
  margin: 0 1rem;
}

.logo {
  color: currentColor;

  &:hover {
    text-decoration: none;
  }
}
