@import "../../data/colors";

.CalendarItem {
  position: relative;
  width: 100%;
  display: block;
  padding: 2rem 5rem;
  font-weight: bold;
  font-size: 1.4rem;
  overflow: hidden;
  border-radius: 0.5rem;
  box-sizing: border-box;

  &.primary {
    border: 2px solid $colorCopy;

    .background {
      opacity: 0.5;
    }
  }

  &:hover {
    text-decoration: none;

    .background {
      opacity: 1;
    }
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    transition: 0.1s ease-out all;
  }

  .content {
    position: relative;
    color: $colorCopy;
  }
}
