.Calendar {
  padding: 0 1rem;
  max-width: 1000px;
  margin: 0 auto;
}

.OverviewOptions {
  margin: 4rem 0;

  .textFilter {
    margin-left: 2rem;
  }
}

.EventList {
  margin-bottom: 4rem;

  @media print {
    margin-bottom: 0;
  }
}
